import React from "react";
import {Col, Container, Row} from "reactstrap";
import Banner from "../assets/imgs/Leader.jpg";

export default class Leader extends React.Component {
    render() {
        return <section className="section">
            <Container>
                <Row>
                    <Col md="6">
                        <div className="position-relative">
                            <img alt="Leader" className="img-center img-fluid" src={Banner}/>
                        </div>
                    </Col>
                    <Col md="6" className="py-5 py-md-0">
                        <h2>Leader</h2>
                        <p className="lead text-dark">Märts 2010.a. Leader meede 1.2. „Hostel Vana-Vigalas“ tööde käigus
                            renoveeriti hoone katus, lammutati vanad seinad ja tehti õppeklassi tarvis juurdeehitus -
                            projekti maksumus 26 444,40 eurot, rahastati 15 866,65 eurot.</p>
                        <p className="lead text-dark">August 2010.a. Leader meede 1.2.„Hosteli välisfassaadi ehitus“
                            tööde käigus ehitati põrandad, välisterrassid, paigaldati aknad, uksed, soojustati ja kaeti
                            värvitud laudisega – projekti maksumus 26 651,80 eurot, rahastati 15 977,91 eurot.</p>
                        <p className="lead text-dark">Oktoober 2012.a. Leader meede 1.2. „Hirvepargi külalistemaja II
                            korruse väljaehitamine ja mööbli soetamine“ tööde käigus ehitati II korruse neli magamistuba
                            ja kaks vannituba, tubadesse ehitati seinakapid ja õppeklassi soetati lauad ja toolid –
                            projekti maksumus 45 388,17 eurot, rahastati 27 232,90 eurot.</p>
                    </Col>
                </Row>
            </Container>
        </section>;
    }
}