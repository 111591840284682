import React from "react";
import {Col, Container, Row} from "reactstrap";

export default class Footer extends React.Component {
    render() {
        return <footer className="footer has-cards">
            <Container>
                <hr/>
                <Row>
                    <Col>
                        <div className="copyright">© {new Date().getFullYear()}{" "}Heltan</div>
                    </Col>
                </Row>
            </Container>
        </footer>;
    }
}