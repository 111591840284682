import React from "react";
import {Col, Container, Row} from "reactstrap";
import Banner from "../assets/imgs/Banner.jpg";

export default class Hostel extends React.Component {
    render() {
        return <section id="hostel" className="section">
            <Container>
                <Row>
                    <Col md="6">
                        <h2>Hostel</h2>
                        <p className="lead">Meie pisikese hostelina pakume majutusteenust 2014. aastal valminud
                            hoones.</p>
                    </Col>
                    <Col md="6">
                        <div className="position-relative">
                            <img alt="Hostel" className="img-center img-fluid" src={Banner}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>;
    }
}