import React from 'react';
import './assets/plugins/nucleo/css/nucleo.css';
import './assets/scss/index.scss?v1.1.0';
import './assets/css/custom.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createRoot} from "react-dom/client";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();