import React from "react";
import {Col, Container, Row} from "reactstrap";
import ERK from "../assets/imgs/accounting/ERK.jpg";
import ENR from "../assets/imgs/accounting/ENR.jpg";
import MEPY from "../assets/imgs/accounting/MEPY.jpg";

export default class Accounting extends React.Component {
    render() {
        return <section id="accounting" className="section">
            <Container>
                <Row className="text-center">
                    <Col>
                        <h2>Raamatupidamine</h2>
                        <p className="lead">Meie raamatupidaja on liige järgnevates organisatsioonides</p>
                    </Col>
                </Row>
                <Row>
                    <Col md="4" className="py-5 py-md-0">
                        <div className="position-relative">
                            <img alt="ERK" className="img-center img-fluid" src={ERK}/>
                        </div>
                    </Col>
                    <Col md="4" className="py-5 py-md-0">
                        <div className="position-relative">
                            <img alt="ENR" className="img-center img-fluid" src={ENR}/>
                        </div>
                    </Col>
                    <Col md="4" className="py-5 py-md-0">
                        <div className="position-relative">
                            <img alt="MEPY" className="img-center img-fluid" src={MEPY}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>;
    }
}