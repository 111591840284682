import React from "react";
import Header from "./header";
import Hostel from "./hostel";
import Leader from "./leader";
import Accounting from "./accounting";
import Contact from "./contact";

export default class Home extends React.Component {
    render() {
        return <div>
            <Header/>
            <Hostel/>
            <Leader/>
            <Accounting/>
            <Contact/>
        </div>;
    }
}