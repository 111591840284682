import React from "react";
import {Col, Container, Row} from "reactstrap";

export default class Header extends React.Component {
    render() {
        return <div id="about" className="position-relative">
            <section className="section section-lg section-shaped">
                <div className="shape shape-style-1 shape-dark">
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                </div>
                <Container className="d-flex">
                    <Row>
                        <Col>
                            <h1 className="display-3 text-white">Heltan<span>Meie juures on võimalik majutuda ning raamatupidamisteenust tellida</span>
                            </h1>
                            <p className="lead text-white">Oleme ettevõte, kes esialgselt pakkus ainult
                                raamatupidamisteenust. Nüüdseks pakume lisaks ka majutusteenust.</p>
                        </Col>
                    </Row>
                </Container>
                <div className="separator separator-bottom separator-skew">
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1"
                         viewBox="0 0 2560 100" x="0" y="0">
                        <polygon className="fill-white" points="2560 0 2560 100 0 100"/>
                    </svg>
                </div>
            </section>
        </div>;
    }
}