import React from "react";
import {Col, Container, Nav, Navbar, NavbarBrand, NavItem, Row, UncontrolledCollapse} from "reactstrap";
import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";
import Banner from "../assets/imgs/Banner.jpg";
// @ts-ignore
import Headroom from "headroom.js";

interface INavMenuState {
    collapseClasses: string,
    collapseOpen: boolean
}

export default class NavMenu extends React.Component<any, INavMenuState> {
    componentDidMount() {
        const headroom = new Headroom(document.getElementById("navbar-main"));
        headroom.init();
    }

    constructor(props: any) {
        super(props);

        this.state = {
            collapseClasses: "",
            collapseOpen: false
        }
    }

    render() {
        return <header className="header-global">
            <Navbar className="navbar-main navbar-transparent navbar-light headroom" expand="lg" id="navbar-main">
                <Container>
                    <NavbarBrand className="mr-lg-5" to="/" onClick={() => NavMenu.scrollToTop()} tag={Link}>
                        <img alt="Heltan" src={Banner}/>
                    </NavbarBrand>
                    <button className="navbar-toggler" id="navbar_global">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <UncontrolledCollapse toggler="#navbar_global" navbar={true} className={this.state.collapseClasses}
                                          onExiting={() => this.onExiting()} onExited={() => this.onExited()}>
                        <div className="navbar-collapse-header">
                            <Row>
                                <Col className="collapse-brand" xs="6">
                                    <HashLink to="#home">
                                        <img alt="Heltan" src={Banner}/>
                                    </HashLink>
                                </Col>
                                <Col className="collapse-close" xs="6">
                                    <button className="navbar-toggler" id="navbar_global">
                                        <span/>
                                        <span/>
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Nav className="align-items-lg-center" navbar={true}>
                            <NavItem className="py-2 py-lg-0 px-lg-5">
                                <HashLink to="#hostel" className="text-white nav-text-white">
                                    <i className="ni ni-building mr-1"/>
                                    <span className="nav-link-inner--text">Majutamine</span>
                                </HashLink>
                            </NavItem>
                            <NavItem className="py-2 py-lg-0 px-lg-5">
                                <HashLink to="#accounting" className="text-white nav-text-white">
                                    <i className="ni ni-books mr-1"/>
                                    <span className="nav-link-inner--text">Raamatupidamine</span>
                                </HashLink>
                            </NavItem>
                            <NavItem className="py-2 py-lg-0 px-lg-5">
                                <HashLink to="#contact" className="text-white nav-text-white">
                                    <i className="ni ni-email-83 mr-1"/>
                                    <span className="nav-link-inner--text">Kontakt</span>
                                </HashLink>
                            </NavItem>
                        </Nav>
                    </UncontrolledCollapse>
                </Container>
            </Navbar>
        </header>;
    }

    private onExiting() {
        this.setState({
            collapseClasses: "collapsing-out"
        });
    }

    private onExited() {
        this.setState({
            collapseClasses: ""
        });
    }

    private static scrollToTop() {
        window.scrollTo(0, 0);
    }
}