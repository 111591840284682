import React from "react";
import {Badge, Col, Container, Row} from "reactstrap";

export default class Contact extends React.Component {
    render() {
        return <section id="contact" className="section">
            <Container>
                <Row>
                    <Col>
                        <h2 className="text-center">Kontakt</h2>
                        <ul className="list-unstyled">
                            <li className="py-2">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <Badge className="badge-circle mr-3">
                                            <i className="ni ni-square-pin"/>
                                        </Badge>
                                    </div>
                                    <div>
                                        <h6 className="mb-0"><b>Aadress</b>: <span>Hirvepargi tee 33A, Vana-Vigala, Vigala vald, 78003 Raplamaa</span>
                                        </h6>
                                    </div>
                                </div>
                            </li>
                            <li className="py-2">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <Badge className="badge-circle mr-3">
                                            <i className="ni ni-mobile-button"/>
                                        </Badge>
                                    </div>
                                    <div>
                                        <h6 className="mb-0"><b>Telefon</b>: <span><a href="tel:+37256468083">+372 564 680 83</a></span>
                                        </h6>
                                    </div>
                                </div>
                            </li>
                            <li className="py-2">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <Badge className="badge-circle mr-3">
                                            <i className="ni ni-email-83"/>
                                        </Badge>
                                    </div>
                                    <div>
                                        <h6 className="mb-0"><b>E-post</b>: <span><a
                                            href="mailto:hostel@heltan.ee">hostel@heltan.ee</a></span></h6>
                                    </div>
                                </div>
                            </li>
                            <li className="py-2">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <Badge className="badge-circle mr-3">
                                            <i className="ni ni-archive-2"/>
                                        </Badge>
                                    </div>
                                    <div>
                                        <h6 className="mb-0"><b>Registrikood</b>: <span>10940980</span></h6>
                                    </div>
                                </div>
                            </li>
                            <li className="py-2">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <Badge className="badge-circle mr-3">
                                            <i className="ni ni-badge"/>
                                        </Badge>
                                    </div>
                                    <div>
                                        <h6 className="mb-0"><b>KMKR</b>: <span>EE101081106</span></h6>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>;
    }
}