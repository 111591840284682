import React from 'react';
import Footer from "./components/footer";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/home";
import NavMenu from "./components/nav-menu";

class App extends React.Component {
    render() {
        return <BrowserRouter>
            <NavMenu/>
            <main>
                <Routes>
                    <Route index element={<Home/>}/>
                </Routes>
            </main>
            <Footer/>
        </BrowserRouter>;
    }
}

export default App;
